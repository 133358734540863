
.packageFeedTitle:not(:first-child) {
    margin-top: 1rem;
}
.upload-progress {
    align-content: center;
    text-align: center;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.link-button:hover, 
.link-button:focus {
    text-decoration: underline;
    outline: none !important;
}

.feed-name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.feed-name-text {
    margin: 0;
    margin-left: 0.5rem;
}

.textBlock {
    display: flex;
    flex-direction: row;
}

.errorText {
    margin-left: 1rem;
}

.fail-grid {
   
    box-shadow: none;
    overflow-y: hidden;
    overflow-x: auto;
}

.room-name {
    width: 6rem;
}



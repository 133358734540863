.nav-container {
    position:relative;
  }

.filter-dropdown {
    /* position:absolute; */
    z-index: 1000;
    /* right:5rem;
    top: 50%;
    transform:translateY(-50%); */
}

.room-page-header {
    background-color: #e9ecef;
    margin-bottom: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.room-page-header ol.breadcrumb {
    margin-bottom: 0rem;
}

.filter-button {
    width: 14rem;
}

.loading {
    justify-content: center;
    flex-flow: row;
}

.upload-button {
    width: 35px;
    height: 35px;
}

.upload-progress {
    align-content: center;
    text-align: center;
}

.ui-dialog {
    width: auto !important;
}

.BookItRoomPage {
  .right-align {
    justify-content: flex-end;
  }

  .MeetingRoomList {
    $color_1: #fff;

    .rt-thead.-header {
      background: #212529;
      color: $color_1;
    }
    .rt-tr {
      align-items: center;
    }

    .right-align {
      text-align: right;
    }

    .rt-thead {
      .rt-th.-sort-asc {
        box-shadow: inset 0 3px 0 0 #007bff;
      }
      .rt-td.-sort-asc {
        box-shadow: inset 0 3px 0 0 #007bff;
      }
      .rt-th.-sort-desc {
        box-shadow: inset 0 -2px 0 0 #007bff;
      }
      .rt-td.-sort-desc {
        box-shadow: inset 0 -2px 0 0 #007bff;
      }
    }
    p.font-weight-bold {
      margin-bottom: 0px !important;
    }
  }
}

.DevicesPage {
    .right-align {
      justify-content: flex-end;
    }
  
    .connectionInfo {
      display: flex;
      justify-content: flex-end;
    }
    .ui-toolbar__menu {
      z-index: 9;
    }
  }
  .DevicesPageMenu {
    .ui-popup__content__content {
      padding: 0;
      border: none;
    }
  }
  